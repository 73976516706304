import './start.scss';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { debounce } from 'lodash/fp';
import { useStableCallback, useWrapCallback } from '../../hooks/use-stable-callback';
import { mangLocal } from '../../utils/common';
import { ObserveList } from '../../utils/definition';
import { ObserveCheckInfo, getCheckObserveInfo, saveCheckObserveInfo } from '../../service/observe';

import { Row, Col, Button, Card, Form, Checkbox, Input, Select, message, Radio } from 'antd';
const { TextArea } = Input;
const { Option } = Select;

const StartObserve = () => {
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const { version, roadName } = location.state ?? {};

  const [form] = Form.useForm();
  const scrollDomRef = useRef<HTMLDivElement>(null);

  const [nowIndex, setNowIndex] = useState<number>(0);
  const [observeList, setObserveList] = useState<ObserveList>();
  const [checkInfo, setCheckInfo] = useState<ObserveCheckInfo>();
  const [spaceName, setSpaceName] = useState<string>('');

  const initCheck = useStableCallback((index?: number) => {
    if (!checkInfo) return;

    const pointDetail = checkInfo.roadOptionList[index ?? 0];
    if (!pointDetail) return;

    const field: any = {
      userNum: pointDetail.userNum,
      remark: pointDetail.remark,
    };

    const observeList: ObserveList = {};
    pointDetail.roadObserveInfoList.forEach((each, index) => {
      if (each.roadObserveName === '使用工具') {
        field['extra' + index] = each.observeItemList
          .filter((each) => each.checked)
          .map((each) => each.itemName);

        observeList['extra' + index] = {
          name: each.roadObserveName,
          list: each.observeItemList.map((each) => each.itemName),
        };
      } else {
        field['extra' + index] = each.observeItemList
          .filter((each) => each.checked)
          .map((each) => each.itemName)[0];

        observeList['extra' + index] = {
          name: each.roadObserveName,
          list: each.observeItemList.map((each) => each.itemName),
        };
      }
    });

    form.setFieldsValue(field);
    setObserveList(observeList);
    setSpaceName(pointDetail.spaceType);
  });

  const saveNowIndex = useStableCallback(async () => {
    if (!checkInfo) return;
    const saveCheck = { ...checkInfo };
    const nowInfo = { ...saveCheck.roadOptionList?.[nowIndex] };
    saveCheck.roadOptionList[nowIndex] = nowInfo;

    const values = await form.validateFields();
    nowInfo.clientTime = Date.now();
    nowInfo.userNum = values.userNum;
    nowInfo.remark = values.remark;
    if (observeList) {
      nowInfo.roadObserveInfoList = Object.keys(observeList).map((key) => ({
        roadObserveName: observeList[key].name,
        observeItemList: observeList[key].list.map((each: string) => ({
          itemName: each,
          checked: Array.isArray(values[key]) ? values[key].includes(each) : values[key] === each,
        })),
      }));
    }

    return saveCheck;
  });

  const prev = useStableCallback(async () => {
    try {
      const saveCheck = await saveNowIndex();
      mangLocal(`observeInfo_${params.projectId}_${params.roadId}`, {
        ...saveCheck,
        suspendIndex: nowIndex - 1,
        projectId: params.projectId,
        roadId: params.roadId,
      });
      mangLocal('observeName', { roadName, projectId: params.projectId, roadId: params.roadId });
    } catch (err) {}

    setNowIndex(nowIndex - 1);
    initCheck(nowIndex - 1);
  });

  const next = useStableCallback(async () => {
    const saveCheck = await saveNowIndex();
    mangLocal(`observeInfo_${params.projectId}_${params.roadId}`, {
      ...saveCheck,
      suspendIndex: nowIndex + 1,
      projectId: params.projectId,
      roadId: params.roadId,
    });
    mangLocal('observeName', { roadName, projectId: params.projectId, roadId: params.roadId });

    setNowIndex(nowIndex + 1);
    initCheck(nowIndex + 1);
  });

  const onSave = useStableCallback(async () => {
    const saveCheck = await saveNowIndex();
    if (!saveCheck) return;

    const saveParams = {
      ...saveCheck,
      suspendIndex: nowIndex,
      projectId: params.projectId,
      roadId: params.roadId,
    };
    await saveCheckObserveInfo(saveParams);
    message.success('保存成功');
    mangLocal(`observeInfo_${params.projectId}_${params.roadId}`, 'del');
    mangLocal('observeName', 'del');
  });

  const onSaveExit = useWrapCallback(async () => {
    await onSave();
    history.goBack();
  }, debounce(1500));

  useEffect(() => {
    const localObserveInfo = mangLocal(`observeInfo_${params.projectId}_${params.roadId}`);
    getCheckObserveInfo(params.projectId, params.roadId, version)
      .then((res) => {
        if (localObserveInfo && res.suspendIndex < localObserveInfo.suspendIndex) {
          setCheckInfo(localObserveInfo);
        } else {
          setCheckInfo(res);
        }
      })
      .catch(() => {
        message.error('获取点位列表失败');
      });
  }, [params.projectId, params.roadId, version]);

  useEffect(() => {
    setNowIndex(checkInfo?.suspendIndex ?? 0);
    initCheck(checkInfo?.suspendIndex);
  }, [checkInfo, initCheck]);

  useLayoutEffect(() => {
    scrollDomRef.current?.scrollTo(0, 0);
  }, [nowIndex]);

  if (!checkInfo) return null;

  return (
    <div className='start-notes' ref={scrollDomRef}>
      <div className='start-notes-header'>
        <Row>
          <Col xs={0} sm={12}>
            <div className='start-notes-header-title'>路线：{roadName}</div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='flex-x m-s-end'>
              <Button onClick={onSaveExit}>中止观察</Button>
              <Button type='primary' onClick={onSave}>
                保存
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <div className='page-custom'>
        <Card title={`点位号：${nowIndex + 1}`}>
          <Card type='inner' title='点位信息' className='start-notes-card'>
            <Form name='road-observe' form={form}>
              <Form.Item label='空间类型' wrapperCol={{ span: 8 }}>
                <span className='start-notes-card-text'>{spaceName ?? '--'}</span>
              </Form.Item>
              <Form.Item
                label='使用人数'
                name='userNum'
                wrapperCol={{ span: 8 }}
                rules={[{ required: true, message: '请选择人数' }]}
              >
                <Select placeholder='请选择人数'>
                  {checkInfo?.userNumList.map((each, index) => (
                    <Option value={each} key={index}>
                      {each}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {Object.entries(observeList ?? {})?.map(([key, value]) => (
                <Form.Item
                  label={value.name}
                  key={key}
                  name={key}
                  wrapperCol={{ span: 12 }}
                  rules={[{ required: true, message: `请选择${value.name}` }]}
                >
                  {value.name === '使用工具' ? (
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row>
                        {value.list.map((each) => (
                          <Col xs={24} sm={12} md={8} key={each}>
                            <Checkbox value={each}>{each}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  ) : (
                    <Radio.Group style={{ width: '100%' }}>
                      <Row>
                        {value.list.map((each) => (
                          <Col xs={24} sm={12} md={8} key={each}>
                            <Radio value={each}>{each}</Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  )}
                </Form.Item>
              ))}

              <Form.Item label='备注' name='remark' wrapperCol={{ span: 12 }}>
                <TextArea autoSize style={{ width: '100%' }} placeholder='选填' />
              </Form.Item>
            </Form>
          </Card>
        </Card>
      </div>

      <div className='common-buttons flex-x m-s-end minauto'>
        {nowIndex === 0 ? (
          <Button onClick={history.goBack}>取消</Button>
        ) : (
          <Button onClick={prev}>上一个</Button>
        )}

        {nowIndex + 1 < checkInfo?.roadOptionList?.length ? (
          <Button onClick={next} type='primary'>
            下一个
          </Button>
        ) : (
          <Button onClick={onSaveExit} type='primary'>
            保存
          </Button>
        )}
      </div>
    </div>
  );
};

export default StartObserve;
